
import TplVertical from "./vertical.html";
import TplHorizontal from "./horizontal.html";
import TplHorizontalSmall from "./horizontal_small.html";

class Toolbar {

    /**
     * @class Toolbar
     * @constructor
     */
    constructor() {
        this._tip = 4;
        this._color = "rgb(0,0,0)";
        this._tool = "pencil";
        this._element = undefined;
        this._onUndo = undefined;
        this._onDelete = undefined;
    }

    setOnDelete(f) {
        this._onDelete = f;
    }

    setOnUndo(f) {
        this._onUndo = f;
    }

    getTip() {
        return this._tip;
    }

    getColor() {
        return this._color;
    }

    getTool() {
        return this._tool;
    }

    set(tool, tip, col) {
        this._tool = tool;
        this._tip = tip;
        this._color = col;
        this._updateCurrentColor();
        this._updateCurrentTool();
        this._updateCurrentTip();
    }

    insertInto(jq, w, h) {
        if(this._element) {
            this._element.off();
        }

        jq.empty();
        this._element = undefined;

        if(w > h) {
            if(w>370) {
                this._element = $(TplHorizontal);
                this._element.find(".psc_setcolor").on("click", function (ev) {
                    var col = $(ev.target).css("background-color");
                    this._color = "" + col;
                    this._updateCurrentColor();
                }.bind(this));

                this._element.find("#psc_tool_pencil").on("click", function () {
                    this._tool = "pencil";
                    this._updateCurrentTool();
                }.bind(this));
                this._element.find("#psc_tool_eraser").on("click", function () {
                    this._tool = "eraser";
                    this._updateCurrentTool();
                }.bind(this));
                this._element.find("#psc_tool_fill").on("click", function () {
                    this._tool = "fill";
                    this._updateCurrentTool();
                }.bind(this));

                this._element.find("#psc_tip_xs").on("click", function () {
                    this._tip = 2;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_s").on("click", function () {
                    this._tip = 4;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_m").on("click", function () {
                    this._tip = 8;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_l").on("click", function () {
                    this._tip = 12;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_xl").on("click", function () {
                    this._tip = 18;
                    this._updateCurrentTip();
                }.bind(this));

                jq.append(this._element);
            } else {
                this._element = $(TplHorizontalSmall);
                this._element.find(".psc_setcolor").on("click", function (ev) {
                    var col = $(ev.target).css("background-color");
                    this._color = "" + col;
                    this._updateCurrentColor();
                }.bind(this));

                this._element.find("#psc_tool_pencil").on("click", function () {
                    this._tool = "pencil";
                    this._updateCurrentTool();
                }.bind(this));
                this._element.find("#psc_tool_eraser").on("click", function () {
                    this._tool = "eraser";
                    this._updateCurrentTool();
                }.bind(this));
                this._element.find("#psc_tool_fill").on("click", function () {
                    this._tool = "fill";
                    this._updateCurrentTool();
                }.bind(this));

                this._element.find("#psc_tip_xs").on("click", function () {
                    this._tip = 2;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_s").on("click", function () {
                    this._tip = 4;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_m").on("click", function () {
                    this._tip = 8;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_l").on("click", function () {
                    this._tip = 12;
                    this._updateCurrentTip();
                }.bind(this));
                this._element.find("#psc_tip_xl").on("click", function () {
                    this._tip = 18;
                    this._updateCurrentTip();
                }.bind(this));

                jq.append(this._element);
            }
        } else {
            this._element = $(TplVertical);
            this._element.find(".psc_setcolor").on("click", function (ev) {
                var col = $(ev.target).css("background-color");
                this._color = "" + col;
                this._updateCurrentColor();
            }.bind(this));

            this._element.find("#psc_tool_pencil").on("click", function () {
                this._tool = "pencil";
                this._updateCurrentTool();
            }.bind(this));
            this._element.find("#psc_tool_eraser").on("click", function () {
                this._tool = "eraser";
                this._updateCurrentTool();
            }.bind(this));
            this._element.find("#psc_tool_fill").on("click", function () {
                this._tool = "fill";
                this._updateCurrentTool();
            }.bind(this));

            this._element.find("#psc_tip_xs").on("click", function () {
                this._tip = 2;
                this._updateCurrentTip();
            }.bind(this));
            this._element.find("#psc_tip_s").on("click", function () {
                this._tip = 4;
                this._updateCurrentTip();
            }.bind(this));
            this._element.find("#psc_tip_m").on("click", function () {
                this._tip = 8;
                this._updateCurrentTip();
            }.bind(this));
            this._element.find("#psc_tip_l").on("click", function () {
                this._tip = 12;
                this._updateCurrentTip();
            }.bind(this));
            this._element.find("#psc_tip_xl").on("click", function () {
                this._tip = 18;
                this._updateCurrentTip();
            }.bind(this));

            jq.append(this._element);
        }

        this._element.find("#psc_tool_undo").on("click", function() {
            if(typeof this._onUndo === "function") {
                this._onUndo();
            }
        }.bind(this));
        this._element.find("#psc_tool_trash").on("click", function() {
            if(typeof this._onDelete === "function") {
                this._onDelete();
            }
        }.bind(this));

        this._updateCurrentColor();
        this._updateCurrentTip();
        this._updateCurrentTool();
    }

    _updateCurrentColor() {
        if(!this._element) {
            return;
        }

        this._element.find("#psc_current_color").css("background-color", this._color);
    }

    _updateCurrentTip() {
        if(!this._element) {
            return;
        }

        if(this._tip === 2) {
            this._element.find("#psc_current_tip")
                .addClass("brush_xs")
                .removeClass("brush_s")
                .removeClass("brush_m")
                .removeClass("brush_l")
                .removeClass("brush_xl");
            this._element.find("#psc_tip_xs").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tip_s").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_m").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_l").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_xl").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tip === 4) {
            this._element.find("#psc_current_tip")
                .removeClass("brush_xs")
                .addClass("brush_s")
                .removeClass("brush_m")
                .removeClass("brush_l")
                .removeClass("brush_xl");
            this._element.find("#psc_tip_s").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tip_xs").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_m").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_l").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_xl").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tip === 8) {
            this._element.find("#psc_current_tip")
                .removeClass("brush_xs")
                .removeClass("brush_s")
                .addClass("brush_m")
                .removeClass("brush_l")
                .removeClass("brush_xl");
            this._element.find("#psc_tip_m").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tip_xs").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_s").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_l").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_xl").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tip === 12) {
            this._element.find("#psc_current_tip")
                .removeClass("brush_xs")
                .removeClass("brush_s")
                .removeClass("brush_m")
                .addClass("brush_l")
                .removeClass("brush_xl");
            this._element.find("#psc_tip_l").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tip_xs").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_s").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_m").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_xl").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tip === 18) {
            this._element.find("#psc_current_tip")
                .removeClass("brush_xs")
                .removeClass("brush_s")
                .removeClass("brush_m")
                .removeClass("brush_l")
                .addClass("brush_xl");
            this._element.find("#psc_tip_xl").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tip_xs").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_s").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_m").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tip_l").removeClass("btn-primary").addClass("btn-secondary");
        }
    };

    _updateCurrentTool() {
        if(!this._element) {
            return;
        }

        if(this._tool === "pencil") {
            this._element.find("#psc_current_tool")
                .addClass("fa-pencil-alt")
                .removeClass("fa-eraser")
                .removeClass("fa-fill-drip");
            this._element.find("#psc_tool_pencil").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tool_eraser").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tool_fill").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tool === "eraser") {
            this._element.find("#psc_current_tool")
                .removeClass("fa-pencil-alt")
                .addClass("fa-eraser")
                .removeClass("fa-fill-drip");
            this._element.find("#psc_tool_eraser").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tool_pencil").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tool_fill").removeClass("btn-primary").addClass("btn-secondary");
        } else if(this._tool === "fill") {
            this._element.find("#psc_current_tool")
                .removeClass("fa-pencil-alt")
                .removeClass("fa-eraser")
                .addClass("fa-fill-drip");
            this._element.find("#psc_tool_fill").addClass("btn-primary").removeClass("btn-secondary");
            this._element.find("#psc_tool_eraser").removeClass("btn-primary").addClass("btn-secondary");
            this._element.find("#psc_tool_pencil").removeClass("btn-primary").addClass("btn-secondary");
        }
    };

}

export default Toolbar;
