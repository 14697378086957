class PageLobby {

    constructor(client) {
        this._client = client;
    }

    getDivId() {
        return "page_lobby";
    };

    show() {
        $("#plo_ready").prop("disabled", false);
        $("#plo_watch").prop("disabled", false);
        $("body").css("overflow", "auto");
    };

    hide() {
    };
}

export default PageLobby;
