
import io from "socket.io-client";

class Message {

    constructor(socket, onOk, onErr) {
        this._socket = socket;
        this._onOk = onOk;
        this._onErr = onErr;
        this._timedout = false;

        /** @type {number} */
        this._timeout = undefined;
    }

    send(args) {
        //console.log("Emitting '"+args[0]+"'");

        this._timeout = setTimeout(function () {
            console.log("Emitting '" + args[0] + "' timed out");
            if (this._onErr) {
                this._timedout = true;
                this._onErr(new Error("timeout"));
            }
        }.bind(this), 2000);

        args.push(function () {
            // stop timeout
            if (!this._timedout) {
                clearTimeout(this._timeout);
                //console.log("Emitting '"+args[0]+"': success");
                if (this._onOk) {
                    this._onOk.apply(this, arguments);
                }
            }
        }.bind(this));

        this._socket.emit.apply(this._socket, args);
    }
}

class Server {

    /**
     * Communication with the server.
     *
     * @class Server
     * @constructor
     */
    constructor(client) {
        this._client = client;
        var loc = window.document.location;
        this._url = loc.protocol+"//"+loc.hostname+":8090";

        /** @type {io} */
        this._socket = undefined;
    }

    /**
     *
     * @param event {string} the event to emit
     * @param args {...*=} the event parameters
     * @param onOk {function=} optional success callback
     * @param onErr {function=} optional error callback
     */
    emit(event) {
        /** @type {function|undefined} */
        var onOk = undefined;

        /** @type {function|undefined} */
        var onErr = undefined;

        /** @type {*[]} */
        var args = Array.prototype.slice.call(arguments);

        if(typeof args[args.length-1] === "function") {
            onOk = args[args.length-1];          // the function is the onOk function
            args = args.slice(0, args.length-1); // remove last element
        }

        if(typeof args[args.length-1] === "function") {
            // There is a second function at the end
            onErr = onOk; // the function we found first seems to be the onErr function instead
            onOk = args[args.length-1];          // this function is now the onOk function
            args = args.slice(0, args.length-1); // remove last element
        }

        var msg = new Message(this._socket, onOk, onErr);
        msg.send(args);
    }

    isConnected() {
        return this._socket !== undefined;
    }

    disconnect() {
        this._socket.close();
        this._socket = undefined;
    }

    setHandler(handler) {
        this._handler = handler;
    }

    on(event, func) {
        this._socket.on(event, func);
    }

    off(event) {
        this._socket.off(event);
    }

    /**
     *
     */
    connect() {
        console.log("Trying to connect...");

        this._socket = io(this._url, {
            autoConnect: false,
            reconnection: false,
            timeout: 2000
        });

        this._socket.on('connect', function() {
            console.log("Websocket connected: "+this._socket.id);

            if(this._handler && typeof this._handler.onConnect === "function") {
                this._handler.onConnect();
            }
        }.bind(this));

        this._socket.on('connect_timeout', function() {
            console.log("Websocket connect timeout");
            this._socket = undefined;

            if(this._handler && typeof this._handler.onConnect === "function") {
                this._handler.onConnectTimeout();
            }
        }.bind(this));

        this._socket.on('disconnect', function() {
            console.log("Websocket disconnected");
            this._socket = undefined;

            if(this._handler && typeof this._handler.onDisconnect === "function") {
                this._handler.onDisconnect();
            }
        }.bind(this));

        this._socket.open();
    }
}

export default Server;