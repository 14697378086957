
import SVG from "svgjs";
import { easeInOutSine, easeInOutCubic, easeInOutQuart, easeInOutExpo } from "js-easing-functions";

class Pie {

    /**
     *
     * @param domId
     * @class Pie
     * @constructor
     */
    constructor(domId) {
        this._domId = domId;
        this._el = $(domId);
        this._width = this._el.width();
        this._height = this._el.height();
        this._padding = this._width * 0.05;
        this._colorLine4 = "#00ffff";
        this._colorLine3 = "#00e0e0";
        this._colorLine2 = "#00c0c0";
        this._colorLine1 = "#008080";
        this._lineWidth = 0.05;
        this._filled = false;
        this._colorFill = "#404040";
        this._fontColor = "#00c0c0";
        this._fontSizePercent = 40;

        this._el.empty();
        this._el.append("<div class='cd'></div>");
    }

    start(seconds) {
        this.stop();

        this._svg = SVG(this._el.find(".cd")[0]).size(this._width, this._height);

        this._el.find(".cd_number").css({
            "font-size":  this._width * this._fontSizePercent / 100,
            "color": this._fontColor
        });

        this._start = Date.now();
        this._seconds = seconds;
        this._total = seconds*1000;
        this._lastSec = -1;

        this.update();
        this._timer = setInterval(this.update.bind(this), 5);
    }

    stop() {
        if(this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
    };

    update() {
        var now = Date.now();
        var percent = 100 * (now - this._start) / this._total;
        percent = 99-Math.min(Math.max(0,percent),99);

        var sec = Math.max(0, this._seconds - Math.ceil((now - this._start)/1000));
        if(sec!==this._lastSec) {
            this._lastSec = sec;
            var next = this._el.find("div.cd_away");
            var cur = this._el.find("div.cd_shown");
            next.text(sec);
            cur.removeClass("cd_shown").addClass("cd_away");
            next.removeClass("cd_away").addClass("cd_shown");
        }

        if(now - this._start >= this._total) {
            clearInterval(this._timer);
            this._el.find("div.cd_shown").removeClass("cd_shown").addClass("cd_away");
        }

        var starta = -Math.PI/2;
        var ang = (percent) * Math.PI*2/100;
        var cx = this._width/2;
        var cy = this._height/2;
        var x = Math.cos(starta+ang) * (this._width/2-this._padding) + cx;
        var y = Math.sin(starta+ang) * (this._height/2-this._padding) + cy;
        var lg = (ang > Math.PI) ? 1 : 0;
        var p = "M "+cx+" "+this._padding+" A "+(this._width/2-this._padding) + " " + (this._height/2-this._padding)+" 0 "+lg+" 1 "+x+" "+y;
        var pl = p + " L "+cx+" "+cy;
        this._svg.clear();
        if(this._filled) {
            this._svg.path(pl).fill(this._colorFill);
        }
        var thickness = this._width*this._lineWidth;

        var rposx = this._width/2 - this._padding - thickness/2;
        var rposy = this._height/2 - this._padding - thickness/2;
        for(var r = 0 ; r<=3 ; r++) {
            var percent2 = percent;
            var col;
            var thickness2;
            if(r===0) {
                percent2 = easeInOutSine(percent, 0, 100, 100);
                col = this._colorLine1;
                thickness2 = thickness;
            } else if(r===1) {
                percent2 = easeInOutCubic(percent, 0, 100, 100);
                col = this._colorLine2;
                thickness2 = thickness * 0.7;
            } else if(r===2) {
                percent2 = easeInOutQuart(percent, 0, 100, 100);
                col = this._colorLine3;
                thickness2 = thickness * 0.5;
            } else if(r===3) {
                percent2 = easeInOutExpo(percent, 0, 100, 100);
                col = this._colorLine4;
                thickness2 = thickness * 0.3;
            }

            p = this.getArcPath(percent2/100, this._width/2, this._height/2, rposx, rposy);
            this._svg.path(p).stroke({ color: col, opacity: 1, width: thickness2 }).fill({ color: '#000', opacity: 0 });

            rposx -= thickness2;
            rposy -= thickness2;
        }

        var fontSize = Math.min(this._width, this._height) / 3;
        var seconds = Math.floor((Date.now() - this._start) / 1000);
        this._svg.text(String(this._seconds - seconds))
            .move(this._width / 2, this._height / 2 - fontSize)
            .leading(1.4)
            .fill(this._colorLine1)
            .font({
            family: 'Helvetica', size: fontSize,
            anchor: 'middle'
        });

    }

    getArcPath(fac, cx, cy, rx, ry) {
        var lg = (fac > 0.5) ? 1 : 0;
        var ang = Math.PI*2*fac;
        var x = Math.cos(ang-Math.PI/2) * rx + cx;
        var y = Math.sin(ang-Math.PI/2) * ry + cy;
        return "M "+cx+" "+(cy-ry)+" A "+ rx + " " + ry +" 0 "+lg+" 1 "+x+" "+y;
    }
}

export default Pie;
