class PageScribble {

    constructor(client) {
        this._client = client;
    }

    getDivId() {
        return "page_scribble";
    };

    show() {
        $("body").css("overflow", "hidden");
    };

    hide() {
    };
}

export default PageScribble;
