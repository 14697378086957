
import Nicknames from "./nicknames.txt";

class PageCreateUser {

    constructor(client) {
        this._client = client;
        this._nicknames = Nicknames.split(/\n/);
    }

    getDivId() {
        return "page_createuser";
    };

    show() {
        if ($("#pcu_name").val().trim() === "") {
            $("#pcu_name").val(this._nicknames[Math.floor(Math.random() * this._nicknames.length)]);
        }
        $("body").css("overflow", "auto");
    };

    hide() {
    };
}

export default PageCreateUser;
