
import Server from "./server";
import PageNavi from "./pagenavi/pagenavi";
import TplScoreEntry from "../tpl/score_entry.html";
import TplTotalScoreEntry from "../tpl/total_score_entry.html";
import TplChatEntry from "../tpl/chat_entry.html";
import TplDrawPlayerEntry from "../tpl/draw_player_entry.html";
import TplToast from "../tpl/toast.html";
import TplLobbyPlayerEntry from "../tpl/lobby_player_entry.html";
import Toolbar from "./toolbar/toolbar";
import Clipboard from "clipboard";
import Howl from "howler";
import Pie from "./pie";
import PageCreateUser from "./pagecreateuser";
import PageLobby from "./pagelobby";
import PageScribble from "./pagescribble";

class Client {

    constructor() {
        /** @type {Server} */
        this._server = new Server(this);

        /** @type {PageNavi} object to handle page navigation */
        this._pageNavi = new PageNavi();

        this._drawOps = [];

        this._volume = 0.5;

        this._lastPing = 0;

        this._chatInputs = [];

        this._chatInputPos = -1;

        this._toolbar = new Toolbar();

        this._pageNavi.addPage(new PageCreateUser(this));
        this._pageNavi.addPage(new PageLobby(this));
        this._pageNavi.addPage(new PageScribble(this));

        this._themes = [
            ["styles_default", "/css/styles_default.css", "Standard"],
            ["styles_dark", "/css/styles_dark.css", "Dunkel"],
            ["styles_unicorn", "/css/styles_unicorn.css", "Einhorn"]
        ];
        var selTheme = $(".themeselect");
        for(var i=0 ; i<this._themes.length ; i++) {
            selTheme.append("<option value='"+this._themes[i][0]+"'>"+this._themes[i][2]+"</option>");
        }

        // Make sure the lobby id is empty - we set it if we need to, but cached values are
        // most of the time invalid, since the lobbys have been discarded in the meantime.
        $("#pcu_lobby").val("");
    }

    getPageNavi() {
        return this._pageNavi;
    }

    _showScreen(id) {
        var screens = [
            "#psc_drawscores",
            "#psc_chooseword",
            "#psc_playerischoosingword",
            "#psc_totalscores",
            "#psc_canvas"
        ];

        for(var i=0 ; i<screens.length ; i++) {
            if(id===screens[i]) {
                $(screens[i]).fadeIn();
            } else {
                $(screens[i]).fadeOut();
            }
        }
    }

    /**
     *
     * @param data {{scores: {nickname: string, score: number, word: string}[]}}
     * @private
     */
    _showDrawScores(data) {
        this._showScreen("#psc_drawscores");

        $("#psc_word").text(data.word);

        var el = $("#psc_drawscores_list");
        el.empty();

        for(var i=0 ; i<data.scores.length ; i++) {
            var entry = $(TplScoreEntry);
            entry.find("[data-id=rank]").text("#"+(i+1));
            entry.find("[data-id=player]").text(data.scores[i].nickname);
            entry.find("[data-id=score]").text(data.scores[i].score);
            el.append(entry);
        }
    }

    /**
     *
     * @param data {{scores: {nickname: string, score: number}[]}}
     * @private
     */
    _showTotalScores(data) {
        this._showScreen("#psc_totalscores");

        var el = $("#psc_totalscores_list");
        el.empty();

        for(var i=0 ; i<data.scores.length ; i++) {
            var entry = $(TplTotalScoreEntry);
            entry.find("[data-id=rank]").text("#"+(i+1));
            entry.find("[data-id=player]").text(data.scores[i].nickname);
            entry.find("[data-id=score]").text(data.scores[i].score);
            el.append(entry);
        }
    }

    showMessage(msg, timeMs) {
        if(timeMs === undefined) {
            timeMs = 2000;
        }
        var el = $(TplToast);
        el.find("[data-id=text]").text(msg);

        el.toast({
            animation: true,
            autohide: true,
            delay: timeMs
        });

        el.on('hidden.bs.toast', function () {
            el.remove();
        });

        var toasts = $("#toasts");
        toasts.append(el);

        el.toast("show");
    }

    start() {
        window.addEventListener("resize", function() {
            clearTimeout(this._resizeTimeout);
            this._resizeTimeout = setTimeout(this._fixCanvasSize.bind(this),200);
        }.bind(this));
        this._fixCanvasSize();

        var query = window.location.search;
        if(query.length > 0) {
            if(query.charAt(0)==="?" && query.length===9) {
                $("#pcu_lobby").val(query.substr(1));
            }
        }

        //this._pageNavi.show("page_scribble");
        //$("#psc_playerischoosingword").show();
        //$("#psc_totalscores").show();
        //$("#psc_drawscores").show();
        //return;

        $("#psc_chat_text .chatentry").remove();
        $("#pcu_theme").val(this._themes[0][0]);

        this._canvas = $("#psc_canvas")[0];
        this._ctx = this._canvas.getContext("2d");

        var serverHandler = {
            onConnect: this._onServerConnect.bind(this),

            onDisconnect: function() {
                this._pageNavi.show("page_createuser");
                $("#connecting").fadeIn();
                window.setTimeout(this._server.connect.bind(this._server), 2000);
            }.bind(this),

            onConnectTimeout: function() {
                window.setTimeout(this._server.connect.bind(this._server), 2000);
            }.bind(this),

            onError: function(err) {
                console.log(err.message);
            }
        };

        this._server.setHandler(serverHandler);
        $("#connecting").fadeIn();
        this._server.connect();

        this._initListeners();
    }

    _onServerConnect() {
        // Connection to Server successful
        $("#connecting").fadeOut();
        this._initServerEvents();
    }

    showUserMenu(id, nickname) {
        $("#usermenu_title").text("Benutzer '"+nickname+"'");
        $("#usermenu_playerid").text(id);
        $("#usermenu_nickname").text(nickname);
        $("#dlg_usermenu").modal();
    }

    _initServerEvents() {
        var handleError = function(err) {
            // TODO error handling
            console.log(err);
        }.bind(this);

        this._server.on("exit", function(ack) {
            ack();
            this._server.disconnect();
        }.bind(this));

        this._server.on("playSound", function(name, ack) {
            var sound = new Howl.Howl({
                src: ['../snd/'+name],
                volume: 0.25 * this._volume
            });
            sound.play();
            ack();
        }.bind(this));

        this._server.on("joinForbidden", function(ack) {
            $("#dlg_joinforbidden").modal();
            ack();
        });

        this._server.on("showUserAway", function(ack) {
            // Show away warning
            $("#dlg_useraway").modal({
                backdrop: 'static'
            });
            ack();
        });

        this._server.on("hideUserAway", function(ack) {
            $("#dlg_useraway").modal("hide");
            ack();
        });

        this._server.on("message", function(msg, time, ack) {
            this.showMessage(msg, time);
            ack();
        }.bind(this));

        this._server.on("gotoPage", function(page, ack) {
            console.log("received gotoPage "+page);
            this._pageNavi.show(page);
            ack();
        }.bind(this), handleError);

        this._server.on("updatePlayerList", function(data, ack) {
            var i;

            console.log("received updatePlayerList");

            var url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "?" + data.lobbyId;
            $("#plo_link").val(url);
            $("#psc_link").val(url);
            this._lobbyUrl = url;
            history.replaceState({}, "", this._lobbyUrl);

            if(this._pageNavi.getCurrentPage() === "page_scribble") {
                $("#psc_player_list_list").empty();

                $("#psc_lobbyid").text("Lobby "+data.lobbyId);

                var list = data.players.slice();
                list.sort(function (a, b) {
                    return b.score - a.score;
                });

                var place = 0;
                var placeScore = -1;

                for (i = 0; i < list.length; i++) {
                    if (list[i].score < placeScore || placeScore === -1) {
                        place++;
                        placeScore = list[i].score;
                    }
                    var el = $(TplDrawPlayerEntry);
                    el.find("[data-id=place]").text("#" + place);
                    el.find("[data-id=nickname]").text(list[i].nickname);
                    el.find("[data-id=score]").text(list[i].score);
                    if(list[i].thumb === 1) {
                        el.find("[data-id=thumbsdown]").hide();
                        el.find("[data-id=thumbsup]").show();
                    } else if(list[i].thumb === -1) {
                        el.find("[data-id=thumbsdown]").show();
                        el.find("[data-id=thumbsup]").hide();
                    } else {
                        el.find("[data-id=thumbsdown]").hide();
                        el.find("[data-id=thumbsup]").hide();
                    }
                    if(list[i].guessed) {
                        el.addClass("guessed");
                    }
                    if(list[i].drawing) {
                        el.find("[data-id=drawing]").show();
                    } else {
                        el.find("[data-id=drawing]").hide();
                    }
                    if(list[i].watching) {
                        el.find("[data-id=watching]").show();
                    } else {
                        el.find("[data-id=watching]").hide();
                    }

                    el.on("click", (function(that, id, nickname) {
                            return function() {
                                that.showUserMenu(id, nickname);
                            };
                        })(this, list[i].playerId, list[i].nickname)
                    );

                    $("#psc_player_list_list").append(el);
                }
            } else if(this._pageNavi.getCurrentPage() === "page_lobby") {
                $("#plo_id").text(data.lobbyId);
                $("#plo_players tbody").empty();
                for(i=0 ; i<data.players.length ; i++) {
                    var el = $(TplLobbyPlayerEntry);
                    el.find("[data-id=nickname]").text(data.players[i].nickname);
                    el.find("[data-id=ready]").toggle(data.players[i].ready);
                    el.find("[data-id=notready]").toggle(!data.players[i].ready && !data.players[i].watching);
                    el.find("[data-id=watching]").toggle(data.players[i].watching);
                    el.find("[data-id=score]").text(data.players[i].score);
                    el.attr("data-id", data.players[i].playerId);
                    el.attr("data-nickname", data.players[i].nickname);
                    el.on("click", function(ev) {
                        var id = $(ev.target).closest("tr").attr("data-id");
                        var nickname = $(ev.target).closest("tr").attr("data-nickname");
                        this.showUserMenu(id, nickname);
                    }.bind(this));

                    $("#plo_players tbody").append(el);
                }
            }
            ack();
        }.bind(this));

        this._server.on("startStartGameCountDown", function(seconds, ack) {
            console.log("received startStartGameCountDown");
            this._startCountDown(seconds);
            ack();
        }.bind(this));

        this._server.on("stopStartGameCountDown", function(ack) {
            console.log("received stopStartGameCountDown");
            this._stopCountDown();
            ack();
        }.bind(this));

        this._server.on("chooseWord", function(seconds, words, roundNr, roundMax, ack) {
            console.log("received chooseWord: "+words.join());
            this._words = words;

            this._resetDrawing();

            $("#psc_roundinfo span").text("Runde "+roundNr+" von "+roundMax);
            $("#psc_wordinfo").text("");
            $("#psc_chooseword1").text(words[0]);
            $("#psc_chooseword2").text(words[1]);
            $("#psc_chooseword3").text(words[2]);
            ack();

            this._showScreen("#psc_chooseword");

            $("#psc_chooseword_timer").css("width", "100%");
            $("#psc_chooseword_timer").animate({
                width: "0%"
            }, seconds*1000, "linear");
        }.bind(this));

        this._server.on("forceWord", function(word, ack) {
            console.log("timeout. word forced = "+word);
            ack();
        }.bind(this));

        this._server.on("playerIsChoosingAWord", function(nickname, roundNr, roundMax, ack) {
            console.log("received playerIsChoosingAWord: "+nickname);

            $("#psc_roundinfo span").text("Runde "+roundNr+" von "+roundMax);

            this._resetDrawing();

            this._ctx.fillStyle = "#ffffff";
            this._ctx.fillRect(0, 0, this._ctx.width, this._ctx.height);

            $("#psc_playerischoosingword").text(nickname+" wählt ein Wort...");

            this._showScreen("#psc_playerischoosingword");

            ack();
        }.bind(this));

        this._server.on("setGuessWord", function(word, ack) {
            $("#psc_wordinfo").text(word);
            ack();
        }.bind(this));

        this._server.on("startGuessing", function(time, timeLeft, ack) {
            console.log("received startGuessing");
            this._drawingAllowed = false;
            $("#psc_tools").hide();
            $("#psc_draw_timer").css("width", Math.floor(100 * timeLeft / time) + "%");
            $("#psc_draw_timer").animate({
                width: "0%"
            }, timeLeft*1000, "linear");

            this._showScreen("#psc_canvas");

            this._resetDrawing();

            ack();
        }.bind(this));

        this._server.on("startDrawing", function(word, time, ack) {
            console.log("received startDrawing");
            this._drawingAllowed = true;

            this._toolbar.set("pencil", 4, "rgb(0,0,0)");

            $("#psc_tool_pencil").trigger("click");
            $("#psc_tip_s").trigger("click");
            $("#psc_current_color").css("background-color", this._toolbar.getColor());

            $("#psc_tools").show();

            this._resetDrawing();

            $("#psc_draw_timer").css("width", "100%");
            $("#psc_draw_timer").animate({
                width: "0%"
            }, time*1000, "linear");
            $("#psc_wordinfo").text(word);

            this._showScreen("#psc_canvas");

            ack();
        }.bind(this));

        this._server.on("draw", function(data, ack) {
            //console.log("received draw");
            this._drawOps.push(data);
            this.performOp(data);
            ack();
        }.bind(this));

        this._server.on("catchupDrawing", function(data, ack) {
            console.log("received catchupDrawing");
            ack();

            this._drawOps = [];
            for(var i=0 ; i<data.length ; i++) {
                this._drawOps.push(data[i]);
                this.performOp(data[i]);
            }
        }.bind(this));

        this._server.on("showDrawScores", function(data, ack) {
            console.log("received showDrawScores");
            $("#psc_draw_timer").stop();
            this._showDrawScores(data);
            ack();
        }.bind(this));

        this._server.on("showTotalScores", function(data, ack) {
            console.log("received showTotalScores");
            this._showTotalScores(data);
            ack();
        }.bind(this));

        this._server.on("showThumbs", function(show, ack) {
            if(show) {
                $("#psc_thumbs").show();
            } else {
                $("#psc_thumbs").hide();
            }
            ack();
        }.bind(this));

        this._server.on("showTipp", function(idx, c, ack) {
            console.log("received showTipp");
            var txt = $("#psc_wordinfo").text();
            txt = txt.substr(0, idx) + c + this.substr(idx + 1);
            $("#psc_wordinfo").text(txt);
            ack();
        }.bind(this));

        this._server.on("chatMessage", function(nickname, text, color, ack) {
            console.log("received chatMessage");
            var el = $(TplChatEntry);
            el.find("[data-id=player]").text(nickname);

            var txtElement = el.find("[data-id=text]");
            txtElement.text(text);

            if(color===0) {
                txtElement.addClass("chattextcol0");
            } else if(color===1) {
                txtElement.addClass("chattextcol1");
            } else if(color===2) {
                txtElement.addClass("chattextcol2");
            } else if(color===3) {
                txtElement.addClass("chattextcol3");
            }

            $("#psc_chat_text").append(el);

            $("#psc_chat_scroller").animate({ scrollTop: $('#psc_chat_scroller')[0].scrollHeight}, 300);

            ack();
        }.bind(this));

        this._server.on("stopDrawing", function(ack) {
            console.log("received stopDrawing");
            this._drawingAllowed = false;
            ack();
        }.bind(this));
    }

    _activateThemeIdx(idx) {
        var gp = $("<div style='display: none; background-color: #808080; position: fixed; left: 0; top: 0; z-index: 9999; width: 100vw; height: 100vh'></div>");
        $("body").css("overflow","hidden").append(gp)

        gp.fadeIn(500, function() {
            $("#theme").attr("href", this._themes[idx][1]);

            setTimeout(function() {
                var bodyBrightness = $("body").css("background-color");

                var e = /^rgb(?:a)?\(([0-9]{1,3}),\s([0-9]{1,3}),\s([0-9]{1,3})(?:,\s)?([0-9]{1,3})?\)$/;
                var rgba = bodyBrightness.match(e);
                var brightness = 2.99 * rgba[1] + 5.87 * rgba[2] + 1.14 * rgba[3];
                if(brightness < 1275) {
                    $("#logo75").attr("src", "/img/logo75wt.png");
                    $("#logo40").attr("src", "/img/logo40wt.png");
                    $("#bytepig40").attr("src", "/img/bytepig40wt.png");
                    $("#logo30").attr("src", "/img/logo30wt.png");
                    $("#bytepig30").attr("src", "/img/bytepig30wt.png");
                } else {
                    $("#logo75").attr("src", "/img/logo75.png");
                    $("#logo40").attr("src", "/img/logo40.png");
                    $("#bytepig40").attr("src", "/img/bytepig40.png");
                    $("#logo30").attr("src", "/img/logo30.png");
                    $("#bytepig30").attr("src", "/img/bytepig30.png");
                }

                gp.fadeOut(500, function() {
                    $("body").css("overflow","");
                    gp.remove();
                }.bind(this));
            }.bind(this), 100);

        }.bind(this));
    }

    _initListeners() {
        var handleError = function(err) {
            // TODO error handling
            console.log(err);
        }.bind(this);


        var c1 = new Clipboard('#plo_copy_link');
        c1.on('success', function(e) {
            this.showMessage("Lobby-Link in Zwischenablage kopiert!", 2000);
            e.clearSelection();
        }.bind(this));

        var c2 = new Clipboard("#psc_copy_link");
        c2.on('success', function(e) {
            this.showMessage("Lobby-Link in Zwischenablage kopiert!", 2000);
            e.clearSelection();
        }.bind(this));

        $(document).on("touchstart", function() {
            this._ping();
        }.bind(this));

        $(document).on("mousemove", function() {
            this._ping();
        }.bind(this));

        $("form").on("submit", function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        });

        $("#psc_profile").add("#plo_profile").on("click", function() {
            this._server.emit("getPlayerInfo", function(nickname, watching) {
                $("#dup_title").text(nickname);
                $("#dup_name").val(nickname);
                $("#dup_name").removeClass("is-invalid");

                $("#dup_watch").prop("checked", watching);
                $("#dlg_profile").modal();
            });
        }.bind(this));

        $("#dpu_ok").on("click", function() {
            var n = $("#dup_name").val().trim();
            if(n!=="") {
                this._server.emit("updatePlayerInfo", n, $("#dup_watch").is(":checked"));
                $("#dlg_profile").modal("hide");
            } else {
                $("#dup_name").addClass("is-invalid");
            }
        }.bind(this));

        $(".themeselect").on("change", function(ev) {
            var val = $(ev.target).val();
            $(".themeselect").val(val);
            for(var i=0 ; i<this._themes.length ; i++) {
                if(this._themes[i][0] === val) {
                    this._activateThemeIdx(i);
                }
            }
        }.bind(this));

        $(".volume").rangeslider({
            polyfill: false,
            onSlideEnd: function(position, value) {
                this._volume = value / 100;
                var sound = new Howl.Howl({
                    src: ['../snd/volumeset.mp3'],
                    volume: 0.50 * this._volume
                });
                sound.play();
                $(".volume").val(value).change();
            }.bind(this)
        });

        $("#plo_ready").on("click", function() {
            var ploWatch = $("#plo_watch");

            if(ploWatch.is(":disabled")) {
                ploWatch.prop("disabled", false);
                this._server.emit("butPlayerReady", false, function() {
                }.bind(this), handleError);
            } else {
                ploWatch.prop("disabled", true);
                this._server.emit("butPlayerReady", true, function () {
                }.bind(this), handleError);
            }
        }.bind(this));

        $("#plo_watch").on("click", function() {
            var ploReady = $("#plo_ready");

            if(ploReady.is(":disabled")) {
                $("#plo_ready").prop("disabled", false);
                this._server.emit("butPlayerWatching", false, function() {
                }.bind(this), handleError);
            } else {
                $("#plo_ready").prop("disabled", true);
                this._server.emit("butPlayerWatching", true, function() {
                }.bind(this), handleError);
            }
        }.bind(this));

        $("#usermenu_votekick").on("click", function() {
            var playerId = $("#usermenu_playerid").text();
            var playerNickname = $("#usermenu_nickname").text();
            this._server.emit("votekick", playerId, playerNickname);
            $("#dlg_usermenu").modal("hide");
        }.bind(this));

        $("#useraway_no").on("click", function() {
            this._server.emit("stillThere");
            $("#dlg_useraway").modal("hide");
        }.bind(this));

        $("#psc_thumbs_up").on("click", function() {
            this._server.emit("butThumbsUp", function () {
            }.bind(this), handleError);
        }.bind(this));

        $("#psc_thumbs_down").on("click", function() {
            this._server.emit("butThumbsDown", function () {
            }.bind(this), handleError);
        }.bind(this));

        $("#pcu_createlobby").on("click", function() {
            var nickname = $("#pcu_name").val().trim();
            var rounds = $("#pcu_rounds").val();
            var customWords = $("#pcu_customwords").val();
            var onlyCustom = $("#pcu_onlycustomwords").is(":checked");
            var nsfw = $("#pcu_nsfw").is(":checked");
            var drawTime = $("#pcu_drawtime").val();

            if(nickname === "") {
                this.showMessage("Dein Name darf nicht leer sein!", 2000);
            } else {
                var list = [];
                var words = customWords.split(",");
                for(var i=0 ; i<words.length ; i++) {
                    var w = words[i].trim();
                    if(w.length >= 2) {
                        list.push(w);
                    }
                }

                this._server.emit("butCreateLobby", nickname, rounds, list, onlyCustom, nsfw, drawTime, function () {
                }.bind(this), handleError);
            }
        }.bind(this));

        $("#pcu_join").on("click", function() {
            var lobbyId = $("#pcu_lobby").val().trim();
            var nickname = $("#pcu_name").val().trim();

            if(nickname === "") {
                this.showMessage("Dein Name darf nicht leer sein!", 2000);
            } else {
                this._server.emit("butJoinLobby", lobbyId, nickname, function () {
                }.bind(this), handleError);
            }
        }.bind(this));

        $("#pcu_watch").on("click", function() {
            var lobbyId = $("#pcu_lobby").val().trim();
            var nickname = $("#pcu_name").val().trim();
            this._server.emit("butJoinLobbyAsWatching", lobbyId, nickname, function () {
            }.bind(this), handleError);
        }.bind(this));

        $("#plo_leave").on("click", function() {
            this._server.emit("leaveLobby", function() {
                this._stopCountDown();
                this._pageNavi.show("page_createuser");
            }.bind(this), handleError);
        }.bind(this));

        $("#psc_chooseword1").on("click", function() {
            this._server.emit("wordChosen", $("#psc_chooseword1").text(), function() {
            }.bind(this), handleError);
        }.bind(this));
        $("#psc_chooseword2").on("click", function() {
            this._server.emit("wordChosen", $("#psc_chooseword2").text(), function() {
            }.bind(this), handleError);
        }.bind(this));
        $("#psc_chooseword3").on("click", function() {
            this._server.emit("wordChosen", $("#psc_chooseword3").text(), function() {
            }.bind(this), handleError);
        }.bind(this));

        $("#psc_chatinput").on("keyup", function(ev) {
            this._ping();

            if(ev.which === 13) {
                var msg = $("#psc_chatinput").val().trim();
                if(msg !== "") {
                    this._chatInputs.push(msg);
                    if(this._chatInputs.length > 10) {
                        this._chatInputs.splice(10, this._chatInputs.length - 10);
                    }
                    this._chatInputPos = this._chatInputs.length;
                    this._server.emit("chatMessage", msg);
                }
                $("#psc_chatinput").val("").focus();
            } else if(ev.which === 38) {
                if(this._chatInputPos > 0) {
                    this._chatInputPos--;
                    $("#psc_chatinput").val(this._chatInputs[this._chatInputPos]);
                }
            } else if(ev.which === 40) {
                if(this._chatInputPos < this._chatInputs.length-1) {
                    this._chatInputPos++;
                    $("#psc_chatinput").val(this._chatInputs[this._chatInputPos]);
                } else {
                    $("#psc_chatinput").val("");
                }
            }
        }.bind(this));

        $("#psc_chatsend").on("click", function() {
            var msg = $("#psc_chatinput").val().trim();
            if(msg !== "") {
                this._server.emit("chatMessage", $("#psc_chatinput").val());
            }
            $("#psc_chatinput").val("").focus();
        }.bind(this));

        this._toolbar.setOnDelete(function() {
            this._undoable();
            this.performAndSendOp(this._getOpClear());
        }.bind(this));

        this._toolbar.setOnUndo(function() {
            this.performAndSendOp(this._getOpUndo());
        }.bind(this));

        var mdFunc = function(ev) {
            if(!this._drawingAllowed) {
                return;
            }

            var tx = 0;
            var ty = 0;
            if(ev.touches) {
                tx = ev.touches[0].pageX;
                ty = ev.touches[0].pageY;
            } else {
                tx = ev.pageX;
                ty = ev.pageY;
            }

            var x = tx - $('#psc_canvas').offset().left;
            var y = ty - $('#psc_canvas').offset().top;

            if(this._toolbar.getTool() === "pencil" || this._toolbar.getTool() === "eraser") {
                this._downX = x;
                this._downY = y;
                this._down = true;
                this._leftCanvas = false;
                this._undoable();
            } else if(this._toolbar.getTool() === "fill") {
                this._undoable();
                this.performAndSendOp(this._getOpFill(this._toolbar.getColor(), x / this._canvas.width, y / this._canvas.height));
            }

        }.bind(this);
        $("#psc_canvas").on("mousedown", mdFunc);
        $("#psc_canvas").on("touchstart", mdFunc);

        var mdMove = function(ev) {
            if(!this._drawingAllowed) {
                return;
            }

            var tx = 0;
            var ty = 0;
            if(ev.touches) {
                tx = ev.touches[0].pageX;
                ty = ev.touches[0].pageY;
            } else {
                tx = ev.pageX;
                ty = ev.pageY;

                this._down = ((ev.buttons & 1) === 1);

                if(!this._down) {
                    return;
                }
            }

            var x = tx - $('#psc_canvas').offset().left;
            var y = ty - $('#psc_canvas').offset().top;

            if(this._leftCanvas) {
                this._downX = x;
                this._downY = y;
                this._leftCanvas = false;
                return;
            }

            var color = this._toolbar.getColor();
            if(this._toolbar.getTool() === "eraser") {
                color = "rgb(255,255,255)";
            }
            var x1 = this._downX / this._canvas.width;
            var y1 = this._downY / this._canvas.height;
            var x2 = x / this._canvas.width;
            var y2 = y / this._canvas.height;
            this.performAndSendOp(this._getOpDrawLine(color, this._toolbar.getTip(), x1, y1, x2, y2));

            this._downX = x;
            this._downY = y;
        }.bind(this);
        $("#psc_canvas").on("mousemove", mdMove);
        $("#psc_canvas").on("touchmove", mdMove);

        var mdOut = function(ev) {
            this._leftCanvas = true;
        }.bind(this);
        $("#psc_canvas").on("mouseout", mdOut);
        $("#psc_canvas").on("touchend", mdOut);
        $("#psc_canvas").on("touchcancel", mdOut);

        var mdEnter = function(ev) {
            if(!this._drawingAllowed || !this._down) {
                return;
            }

            var tx = 0;
            var ty = 0;
            if(ev.touches) {
                tx = ev.touches[0].pageX;
                ty = ev.touches[0].pageY;
            } else {
                tx = ev.pageX;
                ty = ev.pageY;
            }

            var x = tx - $('#psc_canvas').offset().left;
            var y = ty - $('#psc_canvas').offset().top;

            // start new
            this._downX = x;
            this._downY = y;

            var color = this._toolbar.getColor();
            if(this._toolbar.getTool() === "eraser") {
                color = "rgb(255,255,255)";
            }
            var x1 = this._downX / this._canvas.width;
            var y1 = this._downY / this._canvas.height;
            var x2 = x / this._canvas.width;
            var y2 = y / this._canvas.height;
            this.performAndSendOp(this._getOpDrawLine(color, this._toolbar.getTip(), x1, y1, x2, y2));

        }.bind(this);
        $("#psc_canvas").on("mouseenter", mdEnter);

        var mdUp = function(ev) {
            if(!this._drawingAllowed || !this._down) {
                return;
            }

            var tx = 0;
            var ty = 0;
            if(ev.touches) {
                tx = ev.touches[0].pageX;
                ty = ev.touches[0].pageY;
            } else {
                tx = ev.pageX;
                ty = ev.pageY;
            }

            var x = tx - $('#psc_canvas').offset().left;
            var y = ty - $('#psc_canvas').offset().top;

            var color = this._toolbar.getColor();
            if(this._toolbar.getTool() === "eraser") {
                color = "rgb(255,255,255)";
            }
            var x1 = this._downX / this._canvas.width;
            var y1 = this._downY / this._canvas.height;
            var x2 = x / this._canvas.width;
            var y2 = y / this._canvas.height;
            this.performAndSendOp(this._getOpDrawLine(color, this._toolbar.getTip(), x1, y1, x2, y2));

            this._down = false;
        }.bind(this);
        $("#psc_canvas").on("mouseup", mdUp);
    }

    _ping() {
        if(this._server.isConnected()) {
            if (Date.now() - this._lastPing > 10000) {
                this._lastPing = Date.now();
                this._server.emit("stillThere");
            }
        }
    }

    _getOpUndo() {
        return {
            type: "undo"
        };
    }

    _getOpFill(color, x, y) {
        return {
            type: "fill",
            color: color,
            x: x,
            y: y
        };
    }

    _getOpDrawLine(color, size, x1, y1, x2, y2) {
        return {
            type: "line",
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            color: color,
            size: size
        };
    }

    _getOpClear() {
        return {
            type: "clear"
        }
    }

    performAndSendOp(op) {
        this._drawOps.push(op);
        this.performOp(op);
        this._server.emit("draw", op);
    }

    _undoable() {
        var op = {
            type: "undoable"
        };
        this._drawOps.push(op);
        this._server.emit("draw", op);
    }

    performOp(op) {
        if(op.type==="undo") {
            var idx = this._drawOps.length-1;

            while(idx>0 && this._drawOps[idx].type!=="undoable") {
                idx--;
            }

            if(idx>=0) {
                // delete from here to end
                this._drawOps.splice(idx);
                this._clearCanvas();
                this._redraw();
            }
        } else if(op.type==="clear") {
            this._clearCanvas();
        } else if(op.type==="fill") {
            this._ctx.fillStyle = op.color;
            this._ctx.fillFlood(Math.floor(op.x * this._canvas.width), Math.floor(op.y * this._canvas.height), 128);
        } else if(op.type==="line") {
            this._ctx.lineWidth = op.size;
            this._ctx.strokeStyle = op.color;
            this._ctx.lineCap = "round";
            this._ctx.beginPath();
            this._ctx.moveTo(op.x1 * this._canvas.width, op.y1 * this._canvas.height);
            this._ctx.lineTo(op.x2 * this._canvas.width, op.y2 * this._canvas.height);
            this._ctx.stroke();
        }
    }

    _clearCanvas() {
        this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    }

    _redraw() {
        if(this._ctx) {
            this._clearCanvas();
            for (var i = 0; i < this._drawOps.length; i++) {
                this.performOp(this._drawOps[i]);
            }
        }
    }

    _resetDrawing() {
        this._drawOps = [];
        this._clearCanvas();
    }

    // ============================================================================================

    _startCountDown(seconds) {
        this._countDown = seconds;

        $("#plo_countdown").show();
        this._pie = new Pie("#plo_countdown");
        this._pie.start(seconds);

        /*
        $("#plo_countdown span").text(this._countDown);
        this._countDownInterval = setInterval(function() {
            this._countDown--;
            if(this._countDown<0) {
                this._countDown=0;
            }
            $("#plo_countdown span").text(this._countDown);
            if(this._countDown === 0) {
                clearInterval(this._countDownInterval);
                $("#plo_countdown").hide();
                this._countDownInterval = undefined;
            }
        }.bind(this), 1000);*/
    }

    _stopCountDown() {
        if(this._pie) {
            this._pie.stop();
            this._pie = undefined;
        }
        $("#plo_countdown").hide();
        /*
        clearInterval(this._countDownInterval);
        $("#plo_countdown span").text("");
        this._countDownInterval = undefined;
         */
    }

    _fixCanvasSize() {
        var w = $(window).width();
        var h = $(window).height();

        var marginX = 5;
        var marginY = 5;

        w -= marginX*2;
        h -= marginY*2;

        var posName = "absolute";
        var gutter = 2;

        var sidebarWidthPercent = 25;
        var wordinfoHeight = 48;
        var chatInputHeight = 43;
        var playersHeightPercent = 40;
        var playersHeightPercentPortrait = 25;

        var toolbarWidthPercent = 10;
        var toolbarSize = 60;

        var sidebarWidth = sidebarWidthPercent/100 * w;
        var toolbarHeight = 34;//toolbarHeightPercent * h / 100;

        if(sidebarWidth < 150) {
            sidebarWidth = 150;
        }

        var canvasMaxHeight = 0;
        var canvasMaxWidth = 0;
        var canvasHeight = 0;
        var canvasWidth = 0;
        var canvasXPos = marginX;

        var thumbsWidth = 80;
        var thumbsRight = 20;
        var thumbsTop = 20;

        var footerHeight = 42;

        if(sidebarWidth / h < 0.25) {
            canvasMaxHeight = h - (playersHeightPercentPortrait*h/100 + toolbarHeight + wordinfoHeight);
            canvasMaxWidth = w;
            canvasHeight = Math.min(canvasMaxHeight, canvasMaxWidth);
            canvasWidth = canvasHeight;
            canvasXPos = marginX + ((canvasMaxWidth - canvasWidth) / 2);

            console.log("Layout: Very Portrait");

            $("#psc_wordpreview").css({
                position: posName,
                left: (canvasXPos + gutter)  +"px",
                top: (marginY + gutter) + "px",
                width: (canvasWidth - 2*gutter) + "px",
                height: (wordinfoHeight - 2*gutter) + "px"
            });

            $("#psc_canvas_wrapper").css({
                position: posName,
                top: (marginY + wordinfoHeight + gutter) + "px",
                left: (canvasXPos + gutter) + "px",
                width: (canvasWidth - 2*gutter) + "px",
                height: (canvasHeight - 2*gutter) + "px"
            });
            $("#psc_canvas").attr("width", canvasWidth-2*gutter).attr("height", canvasHeight-2*gutter);
            this._redraw();

            $("#psc_thumbs").css({
                position: posName,
                left: (canvasWidth-thumbsWidth-thumbsRight) + "px",
                top: (thumbsTop) + "px",
                width: thumbsWidth+"px"
            });

            $("#psc_tools").css({
                position: posName,
                top: (marginY + wordinfoHeight+canvasHeight) + "px",
                left: canvasXPos + "px",
                width: canvasWidth + "px",
                height: toolbarHeight + "px"
            });
            this._toolbar.insertInto($("#psc_tools"), canvasWidth, toolbarHeight);

            $("#psc_player_list").css({
                position: posName,
                top: (marginY + wordinfoHeight+canvasHeight+toolbarHeight+gutter)+"px",
                left: marginX + gutter+"px",
                width: (w/2-2*gutter) + "px",
                height: (h-(wordinfoHeight+canvasHeight+toolbarHeight)-2*gutter) + "px"
            });

            $("#psc_chat").css({
                position: posName,
                top: (marginY + wordinfoHeight+canvasHeight+toolbarHeight+gutter)+"px",
                left: (marginX + w/2+gutter)+"px",
                width: (w/2-2*gutter) + "px",
                height: (h-(wordinfoHeight+canvasHeight+toolbarHeight+chatInputHeight)-2*gutter-footerHeight) + "px"
            });

            $("#psc_chat_input").css({
                position: posName,
                top: (marginY + h-chatInputHeight+gutter - footerHeight) +"px",
                left: (marginX + w/2+gutter)+"px",
                width: (w/2-2*gutter) + "px",
                height: (chatInputHeight-2*gutter - toolbarHeight) + "px"
            });

            $("#psc_footer").css({
                position: posName,
                top: (marginY + h-footerHeight) +"px",
                left: (marginX + w/2+gutter)+"px",
                width: (w/2-2*gutter) + "px",
                height: (footerHeight - gutter) + "px"
            })

        } else {
            canvasMaxHeight = h - (wordinfoHeight);
            canvasMaxWidth = w - (sidebarWidth + toolbarSize);
            canvasHeight = Math.min(canvasMaxHeight, canvasMaxWidth);
            canvasWidth = canvasHeight;
            canvasXPos = marginX + ((canvasMaxWidth - canvasWidth) / 2 + toolbarSize);

            var chatY = marginY + (playersHeightPercent / 100 * h);
            var chatH = (h - (playersHeightPercent / 100 * h) - chatInputHeight - footerHeight);

            console.log("Layout normal");

            var toolbarWidth = toolbarSize;
            var newCanvasHeight = canvasHeight + toolbarWidth;
            var newCanvasWidth = canvasWidth + toolbarWidth;
            // If there is space for a toolbar at the bottom AND
            // the place is still there when the canvas has the new, increased size, place it there
            if(h-canvasHeight-wordinfoHeight > toolbarHeight && h-newCanvasHeight-wordinfoHeight > toolbarHeight) {
                canvasXPos = marginX;
                canvasWidth = newCanvasWidth;
                canvasHeight = newCanvasHeight;

                console.log("layout normal - place for tools on bottom");

                // place toolbar at bottom
                $("#psc_tools").css({
                    position: posName,
                    top: (marginY + wordinfoHeight+canvasHeight) + "px",
                    left: marginX + "px",
                    width: canvasWidth,
                    height: (toolbarHeight) + "px"
                });
                this._toolbar.insertInto($("#psc_tools"), canvasWidth, toolbarHeight);

                // place canvas
                $("#psc_canvas_wrapper").css({
                    position: posName,
                    top: (marginY + wordinfoHeight + gutter) + "px",
                    left: (marginX + gutter)+"px",
                    width: (canvasWidth - 2*gutter) + "px",
                    height: (canvasHeight - 2*gutter) + "px"
                });
                $("#psc_canvas").attr("width", canvasWidth - 2*gutter).attr("height", canvasHeight - 2*gutter);
                this._redraw();

                $("#psc_thumbs").css({
                    position: posName,
                    left: (marginX + canvasWidth - thumbsWidth - thumbsRight) + "px",
                    top: (marginY + thumbsTop + gutter) + "px"
                });

                $("#psc_wordpreview").css({
                    position: posName,
                    left: (marginX + gutter)+"px",
                    top: (marginY + gutter)+"px",
                    width: (canvasWidth - 2*gutter) + "px",
                    height: (wordinfoHeight - 2*gutter) + "px"
                });

            } else {
                console.log("layout normal - tools need to go left");

                // Just put the toolbar left of the canvas
                $("#psc_canvas_wrapper").css({
                    position: posName,
                    top: (marginY + wordinfoHeight + gutter) + "px",
                    left: (canvasXPos + gutter) + "px",
                    width: (canvasWidth - 2*gutter) + "px",
                    height: (canvasHeight - 2*gutter) + "px"
                });
                $("#psc_canvas").attr("width", canvasWidth - 2*gutter).attr("height", canvasHeight - 2*gutter);
                this._redraw();

                $("#psc_thumbs").css({
                    position: posName,
                    left: (canvasWidth - thumbsWidth - thumbsRight) + "px",
                    top: (thumbsTop + gutter) + "px"
                });

                $("#psc_wordpreview").css({
                    position: posName,
                    left: (canvasXPos + gutter)+"px",
                    top: (marginY + gutter) + "px",
                    width: (canvasWidth - 2*gutter) + "px",
                    height: (wordinfoHeight - 2*gutter) + "px"
                });

                $("#psc_tools").css({
                    position: posName,
                    top: (marginY + wordinfoHeight) + "px",
                    left: (canvasXPos - toolbarSize)+"px",
                    width: (toolbarSize)+"px",
                    height: (h - wordinfoHeight) + "px"
                });
                this._toolbar.insertInto($("#psc_tools"), toolbarSize, (h-wordinfoHeight));
            }

            $("#psc_player_list").css({
                position: posName,
                top: (marginY + gutter)+"px",
                left: (canvasXPos + canvasWidth + gutter) + "px",
                width: (w - (canvasXPos + canvasWidth) - 2*gutter) + "px",
                height: (playersHeightPercent / 100 * h - 2*gutter) + "px"
            });

            $("#psc_chat").css({
                position: posName,
                top: (chatY + gutter) + "px",
                left: (canvasXPos + canvasWidth + gutter) + "px",
                width: (w-(canvasXPos + canvasWidth) - 2*gutter) + "px",
                height: (chatH - 2*gutter) + "px"
            });

            $("#psc_chat_input").css({
                position: posName,
                top: (chatY + chatH + gutter)+"px",
                left: (canvasXPos + canvasWidth + gutter)+"px",
                width: (w-(canvasXPos + canvasWidth) - 2*gutter) + "px",
                height: (chatInputHeight - 2*gutter) + "px"
            });

            $("#psc_footer").css({
                position: posName,
                top: (chatY + chatH + gutter + chatInputHeight)+"px",
                left: (canvasXPos + canvasWidth + gutter)+"px",
                width: (w-(canvasXPos + canvasWidth) - 2*gutter) + "px",
                height: (footerHeight -2*gutter) + "px"
            })

        }
    }
}

export default Client;