
class PageNavi {

    /**
     * @class PageNavi
     * @constructor
     */
    constructor() {
        /** @type {Object<string, Page>} */
        this._pages = {};

        /** @type {string} */
        this._currentPage = undefined;
    }

    addPage(page) {
        if(this._pages[page.getDivId()] === undefined) {
            this._pages[page.getDivId()] = page;
        }
    }

    show(name, onDone) {
        var i;

        /** @type {string[]} */
        var toHide = [];

        /** @type {string} */
        var toShow = undefined;

        var list = [];
        $.each(this._pages, function(key) {
            list.push(key);
        });

        for(i=0 ; i<list.length ; i++) {
            if(list[i] === name) {
                if(!$("#"+list[i]).is(":visible")) {
                    toShow = list[i];
                }
            } else {
                if($("#"+list[i]).is(":visible")) {
                    toHide.push(list[i]);
                }
            }
        }

        if(toShow !== undefined) {
            $("#" + toShow).show();
            this._pages[toShow].show();
            this._currentPage = toShow;
        }
        for(i=0 ; i<toHide.length ; i++) {
            $("#"+toHide[i]).hide();
            this._pages[toHide[i]].hide();
        }

        if(typeof onDone === "function") {
            onDone();
        }
    }

    getCurrentPage() {
        return this._currentPage;
    }
}

export default PageNavi;